<template>
  <div>
    <span v-if="column">
      <font-awesome-icon
        v-if="sortDirection === 'asc'"
        class
        icon="caret-down"
      />
      <font-awesome-icon v-if="sortDirection === 'desc'" icon="caret-up" />
    </span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'IconSort',
  props: {
    sortDirection: { required: true },
    column: { required: true }
  }
}
</script>
<style lang="scss" scoped>
.ml {
  margin-left: -15px;
}
</style>
